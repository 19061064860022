import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import ProductsSectionTemplate from "../../templates/ProductsSectionTemplate";
import SEOFieldsHelmet from "../../utils/SEOFieldsHelmet";
import withTracker from "../../../blueberry/connectPromise";
import ConsentManager from "../../components/Footer2/components/ConsentManager";
import {
	Container,
	Layout,
	Cover,
	Intro,
	InfoBlocks,
	Heading,
	Spacer,
	Paragraph,
	Section,
	SectionExtended,
	Grid,
	CTA,
	Btn,
	Footer,
} from "./style";
import Icons from "./components/Icons";
import Image from "./components/Image";

class Sustainability extends ProductsSectionTemplate {
	state = {};

	renderHeader = () => null;
	renderFooter = () => null;

	miRef = React.createRef();

	handleClick = (e) => {
		e.preventDefault();
		const section = this.miRef.current;
		section.scrollIntoView({ behavior: "smooth" });
	};

	renderBody() {
		return (
			<Layout>
				<Cover>
					<div className="bg">
						<div></div>
						<div className="image">
							<LazyLoadImage
								src="https://www.noritex.com/uplds/2023/10/29/6iAeufTu7Q9tDHydMreporte_de_sostenibilidad_noritex.jpg"
								alt="Noritex"
							/>
						</div>
					</div>
					<div className="inner">
						<Container>
							<div className="content">
								<div>
									<div className="logo">
										<Icons icon="logo" />
									</div>
								</div>
								<div>
									<h1 className="headline">
										Reporte de Sostenibilidad
										<Icons
											className="line"
											icon="line-horizontal"
										/>
									</h1>
									<h2 className="year">2023</h2>
								</div>
								<div>
									<a
										onClick={this.handleClick}
										className="arrow-down">
										<Icons icon="arrow" />
									</a>
								</div>
							</div>
						</Container>
					</div>
				</Cover>

				<Section ref={this.miRef} id="intro">
					<Container>
						<Spacer desktop="100" tablet="50" />
						<Intro>
							<div className="inner">
								<Heading size="big" align="center">
									<h2>
										En Noritex, creemos que la
										sostenibilidad va más allá de operar de
										manera responsable. Es una oportunidad
										para respaldar a las comunidades de las
										que somos parte y generar un impacto
										positivo en el mundo.
									</h2>
								</Heading>
							</div>
							<Icons
								className="line hide-on-mobile"
								icon="line-vertical"
							/>
						</Intro>
						<Spacer desktop="100" tablet="20" />
					</Container>
				</Section>

				<Section>
					<Container>
						<InfoBlocks>
							<div>
								<Image
									src="https://www.noritex.com/uplds/2023/8/28/Ey8auCHA6PEZCf7nxpaneles_solares.jpg"
									alt="Noritex"
								/>
							</div>
							<div>
								<div>
									<Heading>
										<h2>Sostenibilidad</h2>
									</Heading>
									<Paragraph>
										<p>
											Noritex impulsa la sostenibilidad
											con paneles solares en Colón y
											Ciudad de Panamá, produciendo el 85%
											de nuestro consumo eléctrico.
											Contribuimos al futuro verde al
											aprovechar la energía solar en
											nuestras operaciones, reduciendo
											nuestra huella ambiental y
											promoviendo un entorno más limpio
											para todos.
										</p>
									</Paragraph>
								</div>
							</div>
						</InfoBlocks>
					</Container>
				</Section>

				<Spacer desktop="200" tablet="50" />

				<SectionExtended>
					<Container>
						<InfoBlocks>
							<div>
								<div>
									<Heading>
										<h2>Diversidad e inclusión</h2>
									</Heading>
									<Paragraph>
										<p>
											Un 60% de nuestro equipo directivo
											está formado por mujeres talentosas
											y dedicadas. Valoramos la igualdad
											de género y creemos en el poder de
											la diversidad para impulsar nuestro
											éxito. Líderes que guían nuestro
											camino hacia un futuro inclusivo y
											próspero.
										</p>
									</Paragraph>
								</div>
							</div>
							<div>
								<Image
									src="https://www.noritex.com/uplds/2023/8/28/YdubHT8q2dzNPoi7Xnoritex.jpg"
									alt="Noritex"
								/>
							</div>
						</InfoBlocks>
					</Container>
					<Container>
						<Image
							className="floating-image hide-on-tablet"
							src="https://www.noritex.com/uplds/2023/8/28/bNBeTEsuE8zxwyY8Sntx.jpg"
							alt="Noritex"
						/>
						<Icons
							className="line hide-on-mobile"
							icon="line-vertical"
						/>
					</Container>
				</SectionExtended>

				<Spacer desktop="200" tablet="50" />

				<Section>
					<Container maxWidth="600px">
						<Heading align="right">
							<h2>Bodega Semi-automatizada</h2>
						</Heading>
						<Spacer desktop="20" />
						<Paragraph align="right">
							<p>
								Bodega semi-automatizada de Noritex en la Zona
								Libre de Panamá, con tecnología de vanguardia y
								diseño inteligente, optimizamos el
								almacenamiento y la distribución, garantizando
								la calidad de nuestros productos mientras
								impulsamos el crecimiento de manera sostenible.
							</p>
						</Paragraph>
					</Container>

					<Spacer desktop="100" tablet="50" />

					<Container>
						<Grid desktop="2" desktopGap="50px" mobile="2">
							<div>
								<Image
									aspectRatio="square"
									src="https://www.noritex.com/uplds/2023/8/28/Y5ppfApHMrQNCQdXmntx-almacen.jpg"
									alt="Noritex"
								/>
							</div>
							<div>
								<Image
									aspectRatio="square"
									src="https://www.noritex.com/uplds/2023/8/28/xgMwZRT4LTmR489ekntx-almacen-1.jpg"
									alt="Noritex"
								/>
							</div>
						</Grid>

						<Icons
							style={{
								position: "absolute",
								top: "100%",
								left: "50%",
								marginLeft: "-3px",
								marginTop: "-50px",
							}}
							className="line hide-on-tablet"
							icon="line-vertical"
						/>
					</Container>
				</Section>

				<Spacer desktop="100" tablet="50" />

				<Section>
					<Container maxWidth="600px">
						<Heading align="center">
							<h2>Responsabilidad Social</h2>
						</Heading>
						<Spacer desktop="20" />
						<Paragraph align="center">
							<p>
								Creemos en la transformación a través de la
								educación. Nuestra dedicación a la
								responsabilidad social se refleja en nuestra
								donación de útiles escolares a la niñez.
							</p>
						</Paragraph>
					</Container>

					<Spacer desktop="100" tablet="30" />

					<Container>
						<Grid desktop="3" desktopGap="50px" mobile="2">
							<Image
								aspectRatio="square"
								src="https://www.noritex.com/uplds/2023/9/2/4ywuQJTX9JQKH2DTvntx-social02.jpg"
								alt="Noritex"
							/>
							<Image
								aspectRatio="square"
								src="https://www.noritex.com/uplds/2023/9/2/v2qfqp3BZZNAro3t3ntx-social03.jpg"
								alt="Noritex"
							/>
							<Image
								aspectRatio="square"
								src="https://www.noritex.com/uplds/2023/9/2/RAq6Q3yorYzkgveo7ntx-social01.jpg"
								alt="Noritex"
								className="hide-on-mobile"
							/>
						</Grid>
						<Spacer desktop="20" />
						<Grid desktop="5" desktopGap="20px" mobile="2">
							<Image
								src="https://www.noritex.com/uplds/2023/9/2/sxLiC87psa5bvkinysantini.jpg?d=200x200"
								alt="Noritex"
							/>
							<Image
								src="https://www.noritex.com/uplds/2023/9/2/DaqgJopwDeC5kg4sSIMG-20191016-WA0080.jpg?d=200x200"
								alt="Noritex"
							/>
							<Image
								src="https://www.noritex.com/uplds/2023/9/2/at2P2KcoaipTAq3dxsantini-c.jpg?d=200x200"
								alt="Noritex"
							/>
							<Image
								src="https://www.noritex.com/uplds/2023/9/2/Wj2Pyhok64A5Lb4edntx-apoyo.jpg?d=200x200"
								alt="Noritex"
							/>
							<Image
								src="https://www.noritex.com/uplds/2023/9/2/RBgT88oYAfBzYqfFSntx-apoyo-social.jpg?d=200x200"
								alt="Noritex"
								className="hide-on-mobile"
							/>
						</Grid>
					</Container>
				</Section>

				<Spacer desktop="200" tablet="50" />

				<CTA>
					<div className="image">
						<LazyLoadImage
							src="https://www.noritex.com/uplds/2023/9/2/wRm4YyRht4jMEHSffworld.jpg"
							alt="Noritex"
						/>
					</div>
					<div className="content">
						<div className="inner">
							<Heading size="big" align="center">
								<h2>
									Ayúdanos a crear el futuro de nuestra
									región.
								</h2>
							</Heading>
							<a
								href="https://empleos.noritex.com/"
								target="_blank">
								<Btn className="large">Únete a Noritex</Btn>
							</a>
						</div>
					</div>
				</CTA>

				<Footer>
					<Container>
						<div className="inner">
							<Icons className="logo" icon="logo" />
							<p>SÍGUENOS</p>
							<div className="social">
								<a href="" target="_blank">
									<Icons icon="facebook" />
								</a>
								<a href="" target="_blank">
									<Icons icon="instagram" />
								</a>
								<a href="" target="_blank">
									<Icons icon="linkedin" />
								</a>
							</div>
						</div>
						<Spacer desktop="50" tablet="30" />
						<div className="copy">
							<span>© 2023 Noritex</span>
						</div>
					</Container>
				</Footer>

				<SEOFieldsHelmet
					seo={{
						title: "Reporte de sostenibilidad Noritex 2023",
						description:
							"Conoce nuestro reporte de sostenibilidad Noritex 2023, donde podrás encontrar información sobre nuestra responsabilidad social, diversidad e inclusión, y más.",
						images: [
							"https://www.noritex.com/uplds/2023/10/29/6iAeufTu7Q9tDHydMreporte_de_sostenibilidad_noritex.jpg",
						],
					}}
					defaultDescription="Reporte de sostenibilidad Noritex 2023"
					defaultTitle="Reporte de sostenibilidad Noritex 2023"
					bodyClassName="reset-body back-top-btn-hidden"
				/>

				<ConsentManager bypasConsent={true} />
			</Layout>
		);
	}
}

export default withTracker()(Sustainability);

import React, { useState } from 'react';
import { Container } from "../../../sdk/Layout";
import { Header, Nav, Btn } from '../style';
import InternalLink from "../../../components/InternalLink";
import Icon from "./Icons";

export default function HeaderComponent({lang}) {
  const [open, setOpen] = useState(false);
  return (
    <Header className='header-marca-privada'>
      <div className="inner">
        <div>
            <InternalLink to="/marca-privada">
              <Icon className="logo" icon={lang === 'en' ? 'logo-en' : 'logo-es'} />
            </InternalLink>
        </div>
        <div>
          <Nav className={open ? 'open' : ''}>
            <ul>
              <li>
                <InternalLink to="/marca-privada">
                  Inicio
                </InternalLink>
              </li>
              <li>
                <InternalLink to="/marca-privada/nosotros">
                  Nosotros
                </InternalLink>
              </li>
              <li>
                <InternalLink to="/marca-privada/categorias">
                  Categorías
                </InternalLink>
              </li>
              <li>
                <InternalLink to="/marca-privada/beneficios">
                  Beneficios
                </InternalLink>
              </li>
              <li>
                <InternalLink to="/marca-privada/presencia">
                  Presencia
                </InternalLink>
              </li>
              <li className='btn'>
                <InternalLink to="/marca-privada/registrarse">
                  Registrarse
                </InternalLink>
              </li>
            </ul>
          </Nav>
          <span className="navicon" onClick={() => setOpen(prev => !prev)}>
            <Icon icon="bars" />
          </span>
        </div>
      </div>
    </Header>
  );
}
import React from "react";

function Icons({ icon, className, style }) {
    if (icon === "logo") {
        return (
            <svg className={className} width={188} height={26} viewBox="0 0 188 26" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path d="M70.7149 0.376221C67.9452 0.663454 65.5789 1.744 63.7734 3.54947C62.5971 4.72576 61.7901 5.99096 61.1746 7.65281C60.5318 9.36254 60.2924 10.8603 60.2856 13.0897C60.2856 15.839 60.6959 17.8223 61.6807 19.8261C62.8023 22.0966 64.3547 23.6627 66.5774 24.7501C69.4429 26.1589 73.3958 26.3983 76.5895 25.3587C80.8091 23.9841 83.4626 20.6057 84.2354 15.6201C84.4064 14.5191 84.4064 11.804 84.2354 10.6961C83.3053 4.65054 79.5645 0.94385 73.7993 0.369381C72.9786 0.287313 71.5493 0.294153 70.7149 0.376221ZM73.5873 4.23336C75.5158 4.5206 77.0614 5.47804 78.1146 7.02364C78.5044 7.5981 79.0173 8.71285 79.2294 9.43093C79.8243 11.5031 79.8654 14.4097 79.3319 16.5092C78.8395 18.4173 77.7863 20.0518 76.4596 20.9545C74.7635 22.1034 72.4383 22.4659 70.2772 21.9119C67.6442 21.2417 65.8183 19.0669 65.1617 15.8048C65.0181 15.1141 65.0044 14.8473 65.0044 13.1239C65.0044 11.2911 65.0181 11.168 65.1959 10.3268C65.7977 7.53655 67.2544 5.56695 69.3813 4.68473C70.585 4.17865 72.1511 4.01452 73.5873 4.23336Z" fill="currentColor" />
                <path d="M0.438285 0.909658C0.0416289 1.14902 0.03479 1.21741 0.03479 4.33595C0.03479 6.92106 0.0484679 7.19461 0.15789 7.39294C0.404091 7.85115 -0.163538 7.82379 8.82278 7.82379C17.6928 7.82379 17.2894 7.80328 17.5561 8.20677C17.6723 8.39142 17.6792 8.8086 17.7134 16.6802C17.7476 24.6612 17.7544 24.9621 17.8775 25.1467C18.1442 25.5366 18.1374 25.5366 21.2491 25.5366C24.4497 25.5366 24.4702 25.5366 24.7027 25.0578C24.819 24.8322 24.8258 24.1209 24.8258 13.1718C24.8258 0.355707 24.8532 1.19689 24.4018 0.916498C24.1761 0.77972 24.0941 0.77972 12.4132 0.77972C1.02643 0.77972 0.643453 0.786558 0.438285 0.909658Z" fill="currentColor" />
                <path d="M33.7848 13.1581V25.5366H36.0416H38.2985V18.9165C38.2985 12.9461 38.2574 10.5115 38.1207 8.06999C38.0933 7.52288 38.1001 7.26984 38.148 7.28352C38.189 7.2972 38.5789 7.9127 39.0234 8.64446C39.9672 10.2106 40.8494 11.592 41.5675 12.6452C42.0804 13.3907 50.205 24.8869 50.5196 25.3109L50.6837 25.5366H53.0773H55.4641V13.1581V0.77972H53.1662H50.8684L50.9026 7.97425C50.9231 11.975 50.971 15.8595 51.0188 16.7144C51.0599 17.5624 51.0872 18.4378 51.0735 18.6566L51.053 19.067L50.2324 17.6992C48.7825 15.285 47.9892 14.1293 42.3676 6.18246C41.5811 5.07455 40.4049 3.40586 39.7415 2.46893L38.5515 0.77972H36.1647H33.7848V13.1581Z" fill="currentColor" />
                <path d="M89.1799 13.1513V25.5366H91.471H93.762V20.7493V15.9621H95.3213H96.8737L98.3235 18.0343C99.1237 19.1695 100.423 21.0229 101.216 22.1513C102.01 23.2797 102.871 24.5039 103.118 24.8663L103.576 25.5297L106.298 25.5366C108.459 25.5366 109.013 25.516 108.972 25.4477C108.91 25.3519 108.308 24.5107 107.337 23.1771C103.651 18.1095 101.743 15.4423 101.736 15.3603C101.729 15.2987 101.941 15.1961 102.311 15.0662C102.632 14.9636 103.261 14.6901 103.713 14.4644C105.197 13.7189 106.188 12.7478 106.831 11.4142C107.228 10.5867 107.426 9.85495 107.495 8.93854C107.795 4.83519 105.682 2.10647 101.476 1.1969C99.8691 0.84811 99.5135 0.827595 94.1928 0.800238L89.1799 0.772882V13.1513ZM99.4383 4.70525C101.832 5.09507 102.858 6.12775 102.858 8.13155C102.858 9.90282 102.01 11.0586 100.279 11.6604C99.3425 11.9818 98.515 12.0708 96.0735 12.1118L93.762 12.146V8.33671V4.5206L96.2787 4.56164C98.0637 4.58899 98.9732 4.63003 99.4383 4.70525Z" fill="currentColor" />
                <path d="M113.048 13.1581V25.5366H115.305H117.561V13.1581V0.77972H115.305H113.048V13.1581Z" fill="currentColor" />
                <path d="M121.049 2.66042V4.54112H125.05H129.051V15.0388V25.5366H131.342H133.633V15.0388V4.54112H137.599H141.566V2.66042V0.77972H131.308H121.049V2.66042Z" fill="currentColor" />
                <path d="M145.122 13.1581V25.5366H153.466H161.809V23.6559V21.7752H155.722H149.636V18.2531V14.7311H154.594H159.552V12.8504V10.9697H154.594H149.636V7.7554V4.54112H155.415H161.194V2.66042V0.77972H153.158H145.122V13.1581Z" fill="currentColor" />
                <path d="M164.476 0.807076C164.476 0.834431 166.008 3.0092 170.153 8.84963C170.775 9.73185 171.685 11.0107 172.156 11.6809C172.635 12.358 173.018 12.9461 172.998 12.9872C172.977 13.0487 165.625 23.3618 164.292 25.2151L164.052 25.5366L166.637 25.5229L169.223 25.5024L169.544 25.051C170.283 24.0115 174.023 18.6361 174.83 17.4461C175.309 16.7417 175.719 16.1673 175.747 16.1673C175.774 16.1741 176.055 16.5639 176.376 17.0426C176.923 17.8633 178.448 20.0654 181.088 23.8405L182.271 25.5366H184.836H187.4L187.277 25.3519C187.209 25.2425 185.191 22.4112 182.791 19.0533L178.428 12.9461L179.235 11.804C179.679 11.1749 180.233 10.4021 180.459 10.0806C180.685 9.7592 182.189 7.63914 183.796 5.36179C185.41 3.08443 186.799 1.12167 186.894 0.998566L187.058 0.77972H184.439H181.813L180.479 2.70829C179.748 3.76832 178.729 5.24553 178.223 5.98413C177.71 6.72273 176.957 7.83747 176.547 8.45981C176.13 9.08899 175.774 9.59507 175.747 9.58139C175.719 9.57455 175.241 8.89066 174.687 8.06315C174.126 7.23565 172.772 5.2592 171.664 3.66574L169.66 0.77972H167.068C165.646 0.77972 164.476 0.793398 164.476 0.807076Z" fill="currentColor" />
                <path d="M0.554547 9.71818C0.424608 9.78656 0.253635 9.93018 0.178407 10.0328C0.0416289 10.2243 0.03479 10.2995 0.03479 13.1513C0.03479 15.8253 0.0416289 16.0852 0.15789 16.2562C0.445124 16.6939 0.260474 16.6802 4.48008 16.7144C8.04999 16.7486 8.33038 16.7554 8.50819 16.8717C8.89117 17.1247 8.89117 17.1315 8.89117 21.1733C8.89117 24.7364 8.89801 24.8937 9.02795 25.1057C9.29467 25.5434 9.26047 25.5434 12.5226 25.5229L15.4702 25.5024L15.7027 25.2493L15.9352 24.9895V17.624C15.9352 11.9066 15.9147 10.2174 15.8463 10.0875C15.8053 9.99857 15.6754 9.85495 15.5728 9.77973C15.3813 9.63611 15.3676 9.63611 8.08418 9.61559C1.23844 9.60191 0.773392 9.60875 0.554547 9.71818Z" fill="currentColor" />
                <path d="M0.308373 18.725C0.185272 18.8549 0.069011 19.0601 0.0416554 19.1764C-0.0267337 19.5388 -0.00621698 24.5859 0.069011 24.8595C0.103206 24.9894 0.233145 25.1878 0.349406 25.304L0.554573 25.5024L3.50898 25.5229C6.37448 25.5366 6.47023 25.5366 6.68907 25.3998C7.11308 25.1399 7.11992 25.092 7.09941 21.8709L7.07889 18.9575L6.82585 18.725L6.56597 18.4925H3.55001H0.527218L0.308373 18.725Z" fill="currentColor" />
            </svg>
        );
    }
    if (icon === "arrow") {
        return (
            <svg className={className} width={62} height={62} viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M21 33.6851L31 43.6429M31 43.6429L41 33.6851M31 43.6429V18.7483M61 31.1956C61 35.1187 60.224 39.0033 58.7164 42.6277C57.2087 46.2521 54.999 49.5453 52.2132 52.3193C49.4274 55.0933 46.1203 57.2938 42.4805 58.7951C38.8407 60.2964 34.9397 61.0691 31 61.0691C27.0603 61.0691 23.1593 60.2964 19.5195 58.7951C15.8797 57.2938 12.5726 55.0933 9.7868 52.3193C7.00104 49.5453 4.79125 46.2521 3.28361 42.6277C1.77597 39.0033 1 35.1187 1 31.1956C1 23.2727 4.1607 15.6742 9.7868 10.0719C15.4129 4.46952 23.0435 1.32214 31 1.32214C38.9565 1.32214 46.5871 4.46952 52.2132 10.0719C57.8393 15.6742 61 23.2727 61 31.1956Z" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        )
    }
    if (icon === "line-vertical") {
        return (
            <svg style={style} className={className} width={6} height={306} viewBox="0 0 6 306" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M3 0.333333C1.52724 0.333333 0.333333 1.52724 0.333333 3C0.333333 4.47276 1.52724 5.66667 3 5.66667C4.47276 5.66667 5.66667 4.47276 5.66667 3C5.66667 1.52724 4.47276 0.333333 3 0.333333ZM3.00001 300.333C1.52725 300.333 0.333346 301.527 0.333346 303C0.333346 304.473 1.52725 305.667 3.00001 305.667C4.47277 305.667 5.66668 304.473 5.66668 303C5.66668 301.527 4.47277 300.333 3.00001 300.333ZM2.5 3L2.50001 303L3.50001 303L3.5 3L2.5 3Z" fill="black" />
            </svg>
        )
    }
    if (icon === "line-horizontal") {
        return (
            <svg style={style} className={className} width={418} height={6} viewBox="0 0 418 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.333333 3C0.333333 4.47276 1.52724 5.66667 3 5.66667C4.47276 5.66667 5.66667 4.47276 5.66667 3C5.66667 1.52724 4.47276 0.333333 3 0.333333C1.52724 0.333333 0.333333 1.52724 0.333333 3ZM412.333 3.00004C412.333 4.4728 413.527 5.6667 415 5.6667C416.473 5.6667 417.667 4.4728 417.667 3.00004C417.667 1.52728 416.473 0.333369 415 0.333369C413.527 0.333369 412.333 1.52728 412.333 3.00004ZM3 3.5L415 3.50004L415 2.50004L3 2.5L3 3.5Z" fill="black" />
            </svg>
        )
    }
    if (icon === "facebook") {
        return (
         <svg width={30} height={31} viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15 0.689453C6.71574 0.689453 0 7.40641 0 15.6895C0 23.9737 6.71574 30.6882 15 30.6882C23.2843 30.6882 30 23.9737 30 15.6895C30 7.40641 23.2837 0.689453 15 0.689453ZM18.9697 15.6267H16.3724C16.3724 19.7774 16.3724 24.8899 16.3724 24.8899H12.5226C12.5226 24.8899 12.5226 19.8298 12.5226 15.6267H10.6916V12.3563H12.5226V10.2371C12.5226 8.72032 13.2426 6.3526 16.4083 6.3526L19.2591 6.36356V9.54021C19.2591 9.54021 17.5255 9.54021 17.1892 9.54021C16.8512 9.54021 16.373 9.70895 16.373 10.4302V12.3569H19.3078L18.9697 15.6267Z" fill="#112C55" />
        </svg>
        )
    }
    if (icon === "linkedin") {
        return (
        <svg width={30} height={31} viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M15 0.688965C6.71557 0.688965 0 7.4048 0 15.6887C0 23.9731 6.71557 30.689 15 30.689C23.2839 30.689 30 23.9732 30 15.6887C30 7.4048 23.2839 0.688965 15 0.688965ZM10.5999 22.3064H7.14579V11.8856H10.5999V22.3064ZM8.87286 10.4627H8.85079C7.69121 10.4627 6.94143 9.66194 6.94143 8.6617C6.94143 7.64047 7.71379 6.86236 8.896 6.86236C10.0776 6.86236 10.8053 7.64047 10.8279 8.6617C10.8279 9.66194 10.0776 10.4627 8.87286 10.4627ZM23.0585 22.3064H19.6039V16.731C19.6039 15.3307 19.1043 14.3746 17.8532 14.3746C16.8996 14.3746 16.3311 15.019 16.081 15.6413C15.9901 15.8646 15.9675 16.1751 15.9675 16.4868V22.3065H12.5124C12.5124 22.3065 12.5581 12.8633 12.5124 11.8857H15.9675V13.361C16.4257 12.652 17.2471 11.6409 19.0806 11.6409C21.3546 11.6409 23.0584 13.1302 23.0584 16.331V22.3064H23.0585Z" fill="#112C55" />
        </svg>
        )
    }
    if (icon === "instagram") {
        return (
            <svg width={30} height={31} viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14.878 18.4938C16.3597 18.4938 17.5609 17.2926 17.5609 15.8109C17.5609 14.3291 16.3597 13.1279 14.878 13.1279C13.3963 13.1279 12.1951 14.3291 12.1951 15.8109C12.1951 17.2926 13.3963 18.4938 14.878 18.4938Z" fill="#112C55" />
                <path d="M18.2317 9.22559H11.5244C10.5488 9.22559 9.69516 9.53046 9.14638 10.0792C8.5976 10.628 8.29272 11.4817 8.29272 12.4573V19.1646C8.29272 20.1402 8.5976 20.9939 9.20736 21.6036C9.81711 22.1524 10.6098 22.4573 11.5854 22.4573H18.2317C19.2074 22.4573 20.061 22.1524 20.6098 21.6036C21.2196 21.0549 21.5244 20.2012 21.5244 19.2256V12.5183C21.5244 11.5427 21.2196 10.75 20.6708 10.1402C20.061 9.53046 19.2683 9.22559 18.2317 9.22559ZM14.8781 19.9573C12.561 19.9573 10.7317 18.067 10.7317 15.811C10.7317 13.4939 12.622 11.6646 14.8781 11.6646C17.1342 11.6646 19.0854 13.4939 19.0854 15.811C19.0854 18.128 17.1952 19.9573 14.8781 19.9573ZM19.2074 12.4573C18.6586 12.4573 18.2317 12.0305 18.2317 11.4817C18.2317 10.9329 18.6586 10.5061 19.2074 10.5061C19.7561 10.5061 20.183 10.9329 20.183 11.4817C20.183 12.0305 19.7561 12.4573 19.2074 12.4573Z" fill="#112C55" />
                <path d="M15 0.688965C6.70732 0.688965 0 7.39628 0 15.689C0 23.9816 6.70732 30.689 15 30.689C23.2927 30.689 30 23.9816 30 15.689C30.061 7.39628 23.2927 0.688965 15 0.688965ZM22.9878 19.2255C22.9878 20.628 22.5 21.8475 21.6463 22.7012C20.7927 23.5548 19.5732 23.9816 18.2317 23.9816H11.5854C10.2439 23.9816 9.02439 23.5548 8.17073 22.7012C7.2561 21.8475 6.82927 20.628 6.82927 19.2255V12.5182C6.82927 9.71336 8.71951 7.76214 11.5854 7.76214H18.2927C19.6951 7.76214 20.8537 8.24994 21.7073 9.1036C22.561 9.95726 22.9878 11.1158 22.9878 12.5182V19.2255Z" fill="#112C55" />
            </svg>
        )
    }
    return null;
}

export default Icons;

import styled from "styled-components";
import { Box } from "../../sdk/Layout";
import { Button } from "../../sdk/Button";
import { Control, SelectBox, Radio } from "../../sdk/Form";

export const StyledLayout = styled.div`
  @import url('https://fonts.googleapis.com/css2?family=Mulish:wght@400;600;800&display=swap');
  font-family: 'Mulish', sans-serif;
  font-size: 16px;
  &.home {
    .logo {
      color: ${(props) => props.theme.primaryDarkMode} !important;
      
      @media (max-width: 1024px) {
        color: white !important;
      }
    }
    .header-marca-privada {
      background: transparent;
    }
  }
`;

export const Spacer = styled.div`
  height: ${(props) => props.desktop}px;

  @media (max-width: 1023px) {
    height: ${(props) => props.tablet}px;
  }

  @media (max-width: 768px) {
    height: ${(props) => props.mobile}px;
  }
`;

export const Section = styled.div`
  padding: 100px 20px;

  @media (max-width: 768px) {
    padding: 50px 10px;
  }
`;

export const Heading = styled.div`
  h1, h2, h3, h4, h5, h6 {
    color: ${(props) => props.white ? 'white' : props.theme.primaryDarkMode};
    font-size: 53px;
    font-weight: normal;
    text-align: ${(props) => props.align ? props.align : 'center'};
    text-transform: uppercase;
    span {
      font-weight: 1000;
    }
  }
  &:not(.nowrap) {
    span {
      display: block;
    }
  }

  @media (max-width: 768px) {
    h1, h2, h3, h4, h5, h6 {
      font-size: 30px;
    }
  }
`;

export const Subtitle = styled.h3`
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  text-align: center;
  max-width: 600px;
  margin: auto;
  color: ${(props) => props.theme.primaryDarkMode};

  @media (max-width: 768px) {
    font-size: 20px;

  }
`;

export const Paragraph = styled.div`
  font-size: 20px;
  line-height: 34px;
  font-weight: 400;
  max-width: 800px;
  margin: auto;
  p:not(:last-child) {
    margin-bottom: 30px;
  }
  
  .title {
    font-size: 33px;
    line-height: 1.5;
    font-weight: 400;
    margin-bottom: 40px;
  }
  
  &.center {
    text-align: center;
  }
  &.justify {
    text-align: justify;
  }
  &.small {
    font-size: 16px;
  }

  .countries {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    text-align: left;
    column-gap: 40px;
    li {
      list-style: initial;
      list-style-position: inside;
      font-size: 16px;
      border-bottom: solid 1px ${(props) => props.theme.grayLight};
    }
  }

  @media (max-width: 768px) {
    font-size: 16px;
    line-height: 26px;

    .title {
      font-size: 22px;
      margin-bottom: 16px;
    }
    .countries {
      grid-template-columns: repeat(2, 1fr);
    } 
  }
`;

export const Btn = styled(Button)`
  border-radius: 30px;
  &.borderless {
    border-color: transparent;
  }
  &.raised {
    box-shadow: 0px 4px 31px 15px rgba(0, 0, 0, 0.02);
  }
  &.large {
    height: 60px;
    font-size: 20px;
    padding-left: 40px;
    padding-right: 40px;
  }
`;

export const Header = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9;
  background-color: rgba(0,0,0,.6);
  
  .navicon {
    margin-right: 10px;
    cursor: pointer;
    svg {
      color: white;
      width: 34px;
      height: 34px;
    }
    @media (min-width: 769px) {
      display: none;
    }
  }
  
  .inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;
    width: 100%;
    padding: 0px 16px;
    .logo {
      height: 40px;
      color: white;
      &.primary {
        color: ${(props) => props.theme.primary};
      }
    }

    @media (max-width: 1024px) {
      padding: 0 10px;
      height: 60px;
    }
  }
`;

export const Nav = styled.nav`
  ul {
    list-style: none;
    display: flex;
    align-items: center;
    gap: 28px;
    li {
      a {
        color: #fff;
        text-decoration: none;
        font-size: 16px;
        font-weight: 600;
        display: flex;
        align-items: center;
      }
      &.btn {
        a {
          height: 32px;
          border-radius: 30px;
          background-color: white;
          color: ${(props) => props.theme.primary};
          padding: 0px 8px;
        }
      }
    }
  }
  

  @media (max-width: 768px) {
    background-color: ${(props) => props.theme.primary};
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    padding: 30px 10px;
    ul {
      flex-direction: column;
    }
    &:not(.open) {
      display: none;
    }
  }
`;

export const Slider = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0;
  min-height: 730px;

  .solid {
    background-color: ${(props) => props.theme.blueLight};
  }  
  .bg {
    position: relative;
    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      position: absolute;
      top: 0;
      left: 0;
    }
    .overlay {
      z-index: 1;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 220px;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.50) 0%, rgba(0, 0, 0, 0.00) 100%);
    }
  }
  .inner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    .text {
      display: flex;
      flex-direction: column;
      gap: 20px;
      max-width: 50%;
      align-items: flex-start;
      padding-right: 24px;
      h1 {
        font-size: 53px;
        color: ${(props) => props.theme.primaryDarkMode};
      }
      h2 {
        font-size: 18px;
        line-height: 1.5;
        font-weight: 400;
        max-width: 580px;
        margin-bottom: 10px;
      }
    }
  }

  @media (max-width: 1024px) {
    grid-template-columns: 1fr;
    .solid {
      display: none;
    }
    .bg {
      .overlay {
        display: none;
      }
    }
    .inner {
      background-color: rgba(0,0,0,.6);
      padding: 10px;
      .text {
        margin: auto;
        align-items: center;
        text-align: center;
        max-width: 100%;
        color: white;
        padding-right: 0;
        h1 {
          color: white;
          font-size: 40px;
        }
        h2 {
          font-size: 16px;
        }
      }
    }
  }

  @media (max-width: 768px) {
    min-height: 500px;
    .inner {
      .text {
        h1 {
          font-size: 30px;
        }
      }
    }
  }
`;

export const Featured = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  div {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 20px;
    border-radius: 10px;
    border: solid 1px ${(props) => props.theme.grayLight};
    span {
      svg {
        width: 40px;
        height: 40px;
        color: ${(props) => props.theme.primaryDarkMode};
      }
    }
    h2 {
      font-size: 18px;
      color: ${(props) => props.theme.primaryDarkMode};
      font-weight: 600;
      line-height: 1.3;
      max-width: 320px;
    }
    h3 {
      font-size: 16px;
      line-height: 26px;
      font-weight: normal;
      text-align: justify;
    }
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }

  @media (max-width: 580px) {
    grid-template-columns: repeat(1, 1fr);
    gap: 10px;
  }
`;

export const FeaturedImage = styled.div`
  .inner {
    position: relative;
    background: black;
    h3 {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      width: 100%;
      text-align: center;
      padding: 50px;
      color: white;
      font-size: 53px;

      @media (max-width: 768px) {
        font-size: 24px;
      }
    }
    img {
      opacity: 0.5;
    }
  }
  img {
    display: block;
    width: 100%;
  }

`;

export const CategoryList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;

  .item {
    display: grid;
    grid-template-columns: 1fr 1fr;
    min-height: 400px;
    background-color: ${(props) => props.theme.grayLight};
    &:nth-child(even) {
      .image {
        order: 1;
      }
    }
    .image {
      position: relative;
      img {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        object-fit: cover;
      }
    }
    .content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      .inner {
        display: flex;
        flex-direction: column;
        gap: 30px;
        padding: 10px 50px;
        h3 {
          font-size: 24px;
          font-style: normal;
          font-weight: 600;
          line-height: 120%;

        }
        p {
          font-size: 17px;
          font-style: normal;
          font-weight: 400;
          line-height: 175%; 
          text-align: justify;
        }
      }
    }
  }

  @media (max-width: 768px) {
    gap: 20px;
    .item {
      grid-template-columns: 1fr;
      min-height: auto;
      .image {
        order: 0 !important;
        &:after {
          content: '';
          display: block;
          padding-bottom: 65%;
        }
      }
      .content {
        .inner {
          gap: 10px;
          padding: 20px;
        }
      }
    }
  }
`;


export const Locations = styled(Section)`
  background-color: ${(props) => props.theme.primary};
  color: white;
  background-image: url('/map.png'), url('/map.png');
  background-position: center left, center right;
  background-size: contain;
  background-repeat: no-repeat;

  .inner {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    align-items: center;
    ${Heading} {
      display: flex;
      justify-content: center;
      h2 {
        max-width: 470px;
      }
    }

    ul {
      column-count: 3;
      column-gap: 50px;
      li {
        list-style: initial;
        list-style-position: inside;
        color: #8394AF;
      }
    }
  }

  @media (max-width: 1024px) {
    background-image: url('/map.png');
    background-position: center right;
    .inner {
      grid-template-columns: 1fr;
      gap: 50px;
      ${Heading} {
        h2 {
          text-align: center;
          br {
            display: none;
          }
        }
      }
      ul {
        column-gap: 10px;
      }
    }
  }
`;

export const Hero = styled.div`
  background-color: #232323;
  position: relative;
  &:before {
    content: '';
    display: block;
    padding-bottom: 40%;
  }
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    z-index: 1;
    opacity: 0.8;
  }
  .inner {
    width: 100%;
    padding: 10px;
    padding-bottom: 100px;
    text-align: center;
    z-index: 2;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    h1 {
      color: white;
    }
  }

  @media (max-width: 768px) {
    .inner {
      padding-bottom: 50px;
    }
  }
`;


export const InfoBlocks = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 50px;
  > div {
    @media (min-width: 769px) {
      &:nth-child(even) {
        ${Paragraph} {
          order: -1;
        }
      }
    }
    min-height: 430px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 50px;
    justify-content: space-between;
    align-items: center;

    img {
      display: block;
      width: 100%;
    }
  }

  @media (max-width: 768px) {
    > div {
      grid-template-columns: 1fr;
      gap: 20px;
    }
  }
`;

export const InfoCards = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 50px;
  justify-content: center;
  > div {
    display: flex;
    flex-direction: column;
    gap: 10px;
    text-align: center;
    align-items: center;
    background: #fff;
    box-shadow: 0px 4px 31px 15px rgba(0, 0, 0, 0.02);
    padding: 30px;
    span {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      background-color: ${(props) => props.theme.grayLight};
      display: flex;
      justify-content: center;
      align-items: center;
      svg {
        width: 40px;
        height: 40px;
      }
    }
    h3 {
      font-size: 20px;
      font-weight: bold;
      max-width: 270px;
    }
    p {
      font-size: 16px;
      line-height: 22px;
    }
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
  }
  
  @media (max-width: 580px) {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 10px;
  }
`;


export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  tr {
    td, th {
      border-bottom: solid 1px ${(props) => props.theme.grayLight};
      padding: 20px 10px;
      font-size: 16px;
    }
  }
  thead {
    tr {
      th {
        font-weight: bold;
        font-size: 20px;
      }
    }
  }
`;

export const Form = styled.div`

  .left-column {
    display: none;
    .title {
      display: none;
    }
  }

  .right-column {
    .heading {
      display: none;
    }
  }
  .wrap {
    max-width: 650px;
    padding: 0;
    margin: auto;
  }
  ${Box} {
    padding: 0;
    box-shadow: none;
    border-radius: 0;
  }

  ${Control} {
    input {
      border-radius: 0;
      border: solid 1px ${(props) => props.theme.graySoft};
      background-color: ${(props) => props.theme.grayLight};
      height: 50px;
      box-shadow: none;
    }
  }
  ${SelectBox} {
    select {
      border-radius: 0;
      border: solid 1px ${(props) => props.theme.graySoft};
      background-color: ${(props) => props.theme.grayLight};
      height: 50px;
      box-shadow: none;
    }
  }
  ${Radio} {
    label {
      border-radius: 0;
      border: solid 1px ${(props) => props.theme.graySoft};
      background-color: ${(props) => props.theme.grayLight};
      height: 50px;
      box-shadow: none;
    }
  }
`;

export const Masonry = styled.div`
  column-count: 3;
  column-gap: 10px;
  img {
    width: 100%;
    display: block;
    :not(:last-child) {
      margin-bottom: 10px;
    }
  }
`;

